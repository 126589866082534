import './style.css'
import './main.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DirectionalLightHelper } from 'three'

/**
 * Base
 */
// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
/**
 * Textures
 */
 const textureLoader = new THREE.TextureLoader()
 const particleTexture = textureLoader.load('/textures/particles/1.png')

 /**
 * Particles
 */
//Geometry1
const particlesGeometry = new THREE.BufferGeometry()
const count = 9999

const positions = new Float32Array(count * 3)
const colors = new Float32Array (count * 3)

for(let i = 0; i < count * 3; i++)
{
    positions[i] = (Math.random() - 0.5) * 10
    colors[i] = Math.random()
}

particlesGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(positions,3))

particlesGeometry.setAttribute(
    'color',
    new THREE.BufferAttribute(colors,3))




//Material1
//const particlesMaterial = new THREE.PointsMaterial({ color: parameters.color })
const particlesMaterial = new THREE.PointsMaterial()
 //particlesMaterial.size= (Math.random() * (0.5 - 0.5 + 0.3) + 0.2)
 particlesMaterial.size= (0.7)
 particlesMaterial.sizeAttenuation = true
particlesMaterial.color = new THREE.Color('#bb88ff')
particlesMaterial.transparent = true
particlesMaterial.alphaMap = particleTexture
//! fix 1 particlesMaterial.alphaTest = 0.001
//! fix 2 particlesMaterial.depthTest = false
particlesMaterial.depthWrite = false
particlesMaterial.blending = THREE.AdditiveBlending
particlesMaterial.vertexColors = true

const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)


/**
 * Models
 */
const gltfLoader = new GLTFLoader()
/* gltfLoader.load(
    '/models/Duck/glTF/Duck.gltf',
    (gltf) =>
    {
        scene.add(gltf.scene.children[0])
        console.log('Success')
    
    },
) */

let mixer = null

gltfLoader.load(
    '/models/Lucho/HPHOD.glb',
    (gltf) =>
    {
       // console.log(gltf)
         //!mixer (player para las animaciones del gltf)
         mixer = new THREE.AnimationMixer(gltf.scene)
        const action = mixer.clipAction(gltf.animations[0])
        //console.log(action) 
        action.play() 
//console.log(gltf)
console.log ('este sitio esta creado en honor a Fedemon, lo logramo amigo.')
    ///* const children = [...gltf.scene.children]
    ///*for(const child of children)
    ///*{
    ///*    scene.add(child)
    ///*}
    gltf.scene.scale.set(1,1,1)
    //                           x  y  z
    gltf.scene.position.set(-0.3, -0.6, 0.6)
    gltf.scene.rotation.set(0,0.4,0)
    scene.add(gltf.scene)

    },
     
)


/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(10, 10),
    new THREE.MeshStandardMaterial({
        color: '#444444',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
floor.position.set(-0.3, -0.7, 0.6)
//scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 1)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 5)
const directionaLightR = new THREE.DirectionalLight(0xBB88FF, 1)
directionaLightR.position.set(-60, -60, -30)
const directionaLightB = new THREE.DirectionalLight(0x0000ff, 5)
directionaLightB.position.set(60, 60, 1000)

directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)
scene.add(directionaLightR)
scene.add(directionaLightB)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 1, 2)
//camera.lookAt(0,1,0)
scene.add(camera)

/*  // Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true  */
var angle = 0;
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    particles.rotation.y = elapsedTime *-0.2
    //directionalLight.rotation.y = elapsedTime * -0.2
    angle-=0.1;

    directionaLightR.position.x =20+20*Math.sin(angle);
    directionaLightR.position.y =20+20*Math.cos(angle);
   // directionaLightB.position.x =10+10*Math.sin(angle);
    //directionaLightB.position.y =10+10*Math.cos(angle);
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //Update Mixer
    if(mixer !== null)
    {
    mixer.update(deltaTime)
    }
    // Update controls
   // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()